import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["checkbox", "submit"];

  connect() {
    this.termsChecked();
    console.log("connected");
  }

  termsChecked() {
    this.submitTargets.forEach((el) => {
      el.disabled = !this.checkboxTarget.checked;
      el.classList.toggle("disabled", !this.checkboxTarget.checked);
    });
  }

  verifyAcceptance(event) {
    if (!this.checkboxTarget.checked) {
      event.preventDefault();
      event.stopPropagation();
    }
  }
}
