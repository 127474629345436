import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    if (this.element.dataset.showOnLoad === "true") {
      this.show();
    }
  }

  close(event) {
    event.preventDefault();

    if (this.element.dataset.clearParentOnClose === "true") {
      const frame = this.element.parentNode;

      frame.innerHTML = "";
      frame.removeAttribute("src");
      frame.removeAttribute("complete");
    }

    this.element.classList.add("tw-hidden");
    this.dispatch("close", { target: this.element });
  }

  // Wrapping the browser-native cancel event
  // so that we can prevent esc=>cancel behavior if needed
  // This also fixes the display issue w/ the dialog wrapper
  cancel(e) {
    this.close(e)
  }

  hide() {
    this.element.classList.add("tw-hidden");
    this.dispatch("hide", { target: this.element });
  }

  show() {
    this.element.classList.remove("tw-hidden");
    this.dispatch("show", { target: this.element });
  }
}
